/* General Styles for Sponsor Packages Section */
.sponsor-packages {
    padding: 100px;
    text-align: center;
}

.sponsor-packages h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #26374c;
}

/* Flex Container for Packages */
.packages {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

/* Styles for Each Package */
.package {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    width: 220px;
    margin: 10px;
    color: #fff;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.package:hover {
    transform: translateY(-5px);
}

/* Package Background Colors */
.package-0 {
    background-color: #009999; 
}

.package-1 {
    background-color: #ff9800; 
}

.package-2 {
    background-color: #044471; 
}

.package-3 {
    background-color: #001F44; 
}

/* Popular Package Border */
.package.popular {
    border-color: #ff9800;
}

/* Popular Tag Style */
.popular-tag {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #05a3a4;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 0.9em;
}

/* Package Header and Paragraph Styles */
.package h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
}

.package p {
    margin: 10px 0;
}

/* Sponsor Form Styles */
.sponsor-form {
    margin-top: 20px;
    text-align: left;
}

.sponsor-form div {
    margin-bottom: 10px;
}

.sponsor-form label {
    display: block;
    margin-bottom: 5px;
    color: #ffffff;
}

.sponsor-form input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sponsor-form input[name="message"] {
    height: 50px;
    padding: 10px;
}

/* Sponsor Button Styles */
.sponsor-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.sponsor-button-0 {
    background-color: #F49901; 
    color: #fff;
}

.sponsor-button-1 {
    background-color: #009999; 
    color: #fff;
}

.sponsor-button-2 {
    background-color: #001F44; 
    color: #fff;
}

.sponsor-button-3 {
    background-color: #044471; 
    color: #fff;
}

/* Modal Styles */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.486);
}

.modal.show {
    display: block;
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; 
}

/* Modal Header */
.modal-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.modal-header h2 {
    margin: 0;
}

/* Close Button in Modal */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2.0em; 
    color: #383838;
    transition: color 0.3s ease;
    cursor: pointer;
}

.modal-close:hover {
    color: #000;
}
/* Modal Body */
.modal-body {
    margin-bottom: 20px;
}

/* Modal Footer */
.modal-footer {
    text-align: right;
}

.modal-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.modal-footer .btn-cancel {
    background-color: #ccc;
    color: #333;
}

.modal-footer .btn-confirm {
    background-color: #009999;
    color: #fff;
}
