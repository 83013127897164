html {
  scroll-behavior: smooth;
}
/* FrequentAskQuestions.css */
.faq-section {
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px; /* Adjusted padding */
  padding-bottom: 20px;
}

.faq-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #006373;
  font-family: "Nexa-Bold";
}

.faq-content {
  width: 100%;
  flex-grow: 1; /* This makes the faq-content take up the remaining space */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.faq-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
}
.faq-left h6 {
  font-family: "Nexa-Bold";
  font-size: 1.2rem;
  color:#B35A20;
}

.faq-left p {
  font-family: "Nexa-Light";
  font-size: 1.1rem;
}
.faq-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* background-color: #f0f0f0; Subtle background color */
}

.faq-right img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .faq-section {
    flex-direction: column;
    height: auto;
  }

  .faq-heading {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .faq-left {
    padding: 10px;
  }

  .faq-right {
    display: none;
  }
}

@media (max-width: 480px) {
  .faq-section {
    flex-direction: column;
    height: auto;
  }
  .faq-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .faq-left {
    padding: 5px;
  }
}
@media (max-width: 992px) {
  .faq-section {
    flex-direction: column;
    height: auto;
  }
}
