.creator-banner {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #0D1520;
    height: 300px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
}


.left-contentCB {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.creator-image-left {
    height: 120px;
    border-radius: 50%;
    border: 3px solid #05a3a4;
    margin-top: 300px;
    
}

.Creator-Name {
    margin-left: 20px;
    color: rgb(0, 0, 0);
    cursor: auto;
    margin-top: 370px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .creator-banner {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #0D1520;
    height: 300px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
    }

    .creator-image-left {
        margin-bottom: -300px;
    }

    .Creator-Name {
        margin-top: 10px;
        margin-left: 5px;
        margin-bottom: -340px;
    }
}

@media (max-width: 768px) {
    .creator-banner {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #0D1520;
        height: 300px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
    }

    .left-content {
        flex-direction: column;
        align-items: center;
       
    }

    .creator-image-left {
        height: 100px;
        margin-top: 10px;
    }

    .Creator-Name {
        margin-top: 10px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .creator-banner {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #0D1520;
        height: 300px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
    }

    .left-content {
        flex-direction: column;
        align-items: center;

    }

    .creator-image-left {
        height: 80px;
        margin-top: 90px;
    }

    .Creator-Name {
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
}

/* Additional Styles for Smaller Screens */
@media (max-width: 320px) {
    .creator-banner {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #0D1520;
        height: 300px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
    }

    .left-content {
        flex-direction: column;
        align-items: center;
       
    }

    .creator-image-left {
        height: 60px;
        margin-top: 90px;
        
    }

    .Creator-Name {
        font-size: 12px;
        margin-top: 10px;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .creator-banner {
        height: 100px;
        padding: 10px;
        text-align: center;
        background-size: contain;
        position: relative;
    }

    .left-content {
        flex-direction: column;
        align-items: left;
        
    }

    .creator-image-left {
        height: 80px;
    }

    .Creator-Name {
        margin-top: 10px;
        font-size: 16px;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .creator-banner {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #0D1520;
        height: 200px;
        background-size: cover;
        background-position: center;
        cursor: pointer;
        position: relative;
    }

    .creator-image-left {
        height: 100px;
        margin-top: 50px;
        margin-left: 0px;
        margin-bottom: -20px;
    }

    .Creator-Name {
        font-size: 18px;
        margin-bottom: -300px;
        margin-top: 30px;

    }
}
