ul li a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
}

footer img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 150px; /* Adjust the size as needed */
}

footer ul {
    padding: 0;
    list-style: none;
}

footer ul li {
    margin-bottom: 10px;
}

footer ul li a {
    color: white; /* Ensure the text color is white to match the footer background */
}
