body {
  margin: 0;
  font-family: "nexa-bold";
  overflow-x: hidden; /* Prevent horizontal overflow on the entire page */
}

.containerN {
  display: grid;
  place-items: center;
  height: 100vh;
  padding-top: 80px; /* Adjusted to make space for fixed header */
  background-color: #f8f8f8;
}

.back-arrow {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 100px; /* Adjust based on header height */
}

.form-container {
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.highlight {
  color: #e8891d;
}

.form {
  display: block;
  text-align: center; /* Centering the submit button */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

.input-field {
  padding: 15px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.password-hint {
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

.submit-button {
  padding: 10px;
  background-color: #006373;
  color: white;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block; 
  width: 100%;
  max-width: 230px;
}

.links {
  margin-top: 20px;
}

.links p {
  margin-bottom: 10px;
}

.links a {
  color: #e8891d;
  text-decoration: none;
  font-weight: bold;
}


.links a[href="/login"] {
  color: #006373; 
}

.headerN {
  position: fixed;
  top: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px;
  z-index: 1000;
}

.headerN .logoN {
  grid-column: 2;
  justify-self: center;
}

.headerN .logoN img {
  height: 100px;
  width: 200px;
}

.headerN .navN {
  grid-column: 3;
  justify-self: end;
}

.headerN .nav a {
  margin-left: 20px;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}
