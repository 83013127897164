.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
  }
  
  .header-l{
    position: absolute;
    top: 20px;
    right: 200px;
    font-size: 20px;
  }
  
  .header-l p {
    margin: 0;
  }
  
  .header-l a {
    color: #000;
    text-decoration: none;
    margin-left: 40px; /* Add margin to create space between links */
  }
  
  .header-l a:first-child {
    margin-left: 0; /* No margin for the first link */
  }
  
  .header-l a:hover {
    text-decoration: underline;
  }
  
  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    max-width: 300px; 
    margin-bottom: 0.75rem;
  }
  
  .welcome-message {
    align-self: flex-start;
    margin-left: 6.5rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    margin: 0;
  }
  
  .login-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 0rem;
    font-family: 'nexa extra light';
    font-size: larger;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .btn {
    width: 60%;
    padding: 0.25rem;
    border: none;
    border-radius: 7px;
    background-color: #006373;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .btn:hover {
    background-color: #B35A20;
  }
  
  .google-btn {
    background-color: #0084ff;
    margin-top: 1rem;
  }
  
  .google-btn:hover {
    background-color: #B35A20;
  }
  .google-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .footer {
    margin-top: 1.5rem;
  }
  
  .footer p {
    margin: 0.5rem 0;
  }
  
  .footer a {
    color: #B35A20;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: solid;
  }

  .forgot-password-link {
    display: block;
    margin: 15px 0;
    color: #E8891D;
    text-decoration: none;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }
  
  /* For devices with a maximum width of 768px */
@media only screen and (max-width: 768px) {
  .login-container {
    align-items: flex-start;
  }
  
  .header-l {
    position: static;
    text-align: center;
    margin-bottom: 20px;
    right: 0;
  }

  .welcome-message {
    margin-left: 0;
  }

  .form-group label {
    margin-left: 0;
  }

  .btn {
    width: 100%;
  }

  .google-btn {
    width: 100%;
  }
}

/* For devices with a maximum width of 576px */
@media only screen and (max-width: 576px) {
  .login-box {
    width: 90%;
    padding: 1.5rem;
  }
}

/* For devices with a maximum width of 320px */
@media only screen and (max-width: 320px) {
  .login-container {
    padding: 20px;
  }
}

/* Add this at the end of your CSS file */
.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

/*.header-nav {
  display: none;
}*/

.header-nav.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Adjust as needed */
  right: 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-nav a {
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .header-l {
    text-align: right;
    position: relative;
  }

  .header-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust as needed */
    right: 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .header-nav a {
    color: #000;
    text-decoration: none;
    margin-bottom: 10px;
  }
}
