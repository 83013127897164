@import url('./nexa.css');

body {
  margin: 0;
  font-family: 'Nexa-Light', 'Nexa-Bold', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
