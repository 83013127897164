  .transactions-container {
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  th {
    background-color: #ffffff;
    color: #e8891d;
    font-family: "Nexa-bold";
  }
  
  td {
    background-color: white;
  }
  
  @media (max-width: 1200px) {
    th,
    td {
      padding: 8px;
    }
  }
  
  @media (max-width: 992px) {
    th,
    td {
      padding: 6px;
    }
  }
  
  @media (max-width: 768px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
  
    th {
      display: none;
    }
  
    tr {
      margin-bottom: 20px;
    }
  
    td {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #ccc;
      text-align: left;
    }
  
    td::before {
      content: attr(data-label);
      font-weight: bold;
      flex: 1;
    }
  }
  