.dashboard-app {
  display: flex;
  height: 100vh;
  background-color: #f8f8f8;
}

.dashboard-sidebar {
  width: 250px;
  background: linear-gradient(to bottom, #fffefc, #fbe0b3, #f9d290, #bfd4b6, #90d7d6);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-bottom: 20px;
}

.logo img {
  width: 100px;
  height: 70px;
}

.profile {
  text-align: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
}

.profile input[type="file"] {
  display: none;
}

.profile p {
  cursor: pointer;
  color: #007bff;
}

.profile p:hover {
  text-decoration: underline;
}

.nav {
  margin-top: 70px;
}

.nav ul {
  list-style: none;
  width: 100%;
}

.nav ul li {
  padding: 10px;
  margin: 5px 0;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.nav ul li.active,
.nav ul li:hover {
  background-color: #ff9c5f;
}

.dashboard-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.welcome h1 {
  margin: 0;
}

.search {
  display: flex;
  align-items: center;
  position: relative;
}

.search input {
  padding: 10px 40px 10px 20px;
  font-size: 16px;
  border: 2px solid #009688;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s;
}

.search input:focus {
  border-color: #006373;
}

.search-icon {
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: #009688;
}

.dashboard-content {
  padding: 20px;
  flex: 1;
}

.cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card {
  flex: 1;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  text-align: center;
}

.card:last-child {
  margin-right: 0;
}

.card h2 {
  margin-bottom: 10px;
  color: #e8891d;
}

.balance p {
  font-size: 24px;
  color: #006373;
}

.transactions {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.transactions h2 {
  margin-bottom: 20px;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.transactions-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #e8891d;
}
