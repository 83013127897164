* {
    font-family: "nexa-bold";
}

.header-reusable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    height: 100px;
}

.logo {
    height: 50px;
}

.nav-client {
    display: flex;
    align-items: center;
}

.nav-link {
    margin: 0 15px;
    background: none;
    border: none;
    color: #333;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.nav-link:hover {
    text-decoration: underline; 
    color: #05a3a4;
    background: none;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-client {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #05a3a4;
}

.search-icon-client {
    position: absolute;
    right: 10px;
    color: #05a3a4;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
}

@media (max-width: 768px) {
    .nav-client {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        background-color: #ffffff00;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .nav-client.active {
        display: flex;
        align-items: flex-start; /* Align items to the start (left) */
    }

    .nav-link {
        margin: 10px 0; /* Add margin between links */
        text-align: left; /* Align text to the left */
        width: 100%; /* Take full width */
    }

    .hamburger {
        display: flex;
    }
}
