body {
  margin: 0;
  font-family: "nexa-bold";
  overflow-x: hidden; /* Prevent horizontal overflow on the entire page */
}

.login-container {
    max-width: 200px;
    margin: auto;
    padding: 20px;
  }
  
  .header-l {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .menu-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .header-nav {
    display: none;
    flex-direction: column;
  }
  
  .header-nav.open {
    display: flex;
  }
  
  .header-nav a {
    text-decoration: none;
    color: #007bff;
    margin: 5px 0;
  }
  
  .login-content {
    text-align: center;
  }
  
  .welcome-message h2 {
    margin-bottom: 20px;
    font-family: 'nexa-bold';
  }
  
  .login-box {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 16px;
  }
  
  .footer {
    margin-top: 15px;
  }
  
  .footer a {
    color: #006373;
    text-decoration: none;
  }

  
  .google-logo {
    width: 20px;
    margin-right: 10px;
  }
  
  