body {
  margin: 0;
  font-family: "nexa-bold";
  overflow-x: hidden; /* Prevent horizontal overflow on the entire page */
}

  .containerPR {
    display: grid;
    place-items: center;
    height: 100vh;
    padding-top: 80px; 
    background-color: #f8f8f8;
  }
  
  .back-arrow {
    font-size: 24px;
    cursor: pointer;
    color: #006373;
    padding-left: 20px;
    justify-self: start;
    position: absolute;
    top: 120px;
    left: 20px;
  }
  
  .headerPR {
    position: fixed;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 90px;
  }
  
  .logo {
    justify-self: center;
  }
  
  .logo img {
    height: 40px;
  }
  
  .nav {
    display: flex;
    justify-content: end;
  }
  
  .nav a {
    margin-left: 20px;
    color: #333;
    text-decoration: none;
    font-size: 16px;
  }
  
  .content {
    text-align: center;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: #e8891d;
  }
 
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .email-label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .input-field {
    width: 100%;
    max-width: 1000px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    max-width: 100px; 
    padding: 5px;
    background-color: #006373;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .links {
    margin-top: 20px;
  }
  
  .links p {
    margin-bottom: 10px;
  }
  
  .links a {
    text-decoration: none;
    font-weight: bold;
  }
  
  .highlight {
    color: #e8891d;
  }
  
  .login-link {
    color: #006373;
  }
  