.top-sponsors {
    padding: 20px;
    background-color: #ffffff;
    text-align: center;
    height: auto;
}

.top-sponsors h2{

color: #05a3a4;
}
.sponsor-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px;
    justify-items: left;
    margin-left: 50px;
    margin-top: 100px;
}

.sponsor {
    padding: 10px;
    background-color: #ffffff0a;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
    .sponsor-list {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 768px) {
    .sponsor-list {
        grid-template-columns: 1fr; 
    }
}