body {
  margin: 0;
  font-family: "nexa-bold";
  overflow-x: hidden; /* Prevent horizontal overflow on the entire page */
}

.containerPS {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
  padding-top: 70px; /* Adjusted to make space for the fixed header */
}

.headerPR {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.back-arrow {
  font-size: 24px;
  cursor: pointer;
  color: #006373;
}

.logo {
  text-align: center;
}

.logo img {
  height: 40px;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px; /* Adjust based on header height */
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.highlight {
  color: #e8891d;
}

.login-button {
  margin: 20px 0;
  padding: 15px 30px;
  background-color: #006373;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

.highlight-login {
  font-weight: bold;
}

.links {
  margin-top: 20px;
}

.links p {
  margin-bottom: 10px;
}

.highlight-link {
  color: #e8891d;
  text-decoration: none;
  font-weight: bold;
}