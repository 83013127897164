html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "nexa-bold";
  overflow-x: hidden; /* Prevent horizontal overflow on the entire page */
}

.header {
  display: flex;
  justify-content: center;
  backdrop-filter: blur(2px);
  padding: 5px 40px;
  background: #f8f9fa !important;
  border-bottom: 1px solid #e9ecef;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 60px;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.header .logo {
  margin-top: 15px;
  height: 100%; /* Keep this as 100% to fit the height of the header */
  display: flex;
  align-items: center; /* Center the logo vertically */
}

.header .logo a {
  text-decoration: none;
  color: #000;
  transition: color 0.3s;
}

.header .logo img {
  max-height: 80px; /* Set a larger maximum height for the logo image */
  height: auto; /* Ensure the logo maintains its aspect ratio */
}

.header nav {
  display: flex;
  gap: 30px;
  align-items: center;
}

.header nav a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-family: "nexa-bold";
  font-weight: 500;
  transition: color 0.3s;
}

.header nav a:hover {
  color: #b35a20;
}

.header .auth {
  display: flex;
  gap: 15px;
  align-items: center;
}

.header .auth a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: "nexa-bold";
}

.header .auth a:hover {
  color: #b35a20;
}

.get-started {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000;
  border-radius: 20px;
  color: white !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-family: "nexa-bold";
  transition: background-color 0.3s;
}

.get-started:hover {
  background-color: #333;
}

.header .toggle-button {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.sidebar.active {
  transform: translateX(0);
}

.sidebar .close-button {
  align-self: flex-start;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar .side-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sidebar .side-items a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-family: "nexa-bold";
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar .side-items a:hover {
  background-color: #e9ecef;
  color: #b35a20;
}

.sidebar-bottom {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.sidebar .get-started {
  padding: 10px 20px;
  background-color: #000;
  border-radius: 20px;
  width: 80vw;
  max-width: 300px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-family: "nexa-bold";
  transition: background-color 0.3s;
}

.sidebar .get-started:hover {
  background-color: #b35a20;
}

.main-section {
  background-color: #05a3a4;
  display: flex;
  padding: 5px 0px;
  height: calc(100vh - 80px);
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-side h1 {
  font-family: "nexa-bold";
  margin: 20px;
  font-size: 3rem;
  color: #fff;
}

.left-side p {
  margin: 20px;
  font-size: 3rem;
  color: #e8891d;
  background-color: #006373;
  border-radius: 12px;
  padding: 5px;
}

.left-side p span {
  color: #fff;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #b35a20;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #b35a20;
}

.right-side .image-container {
  padding: 100px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side .image-container img {
  width: 100%;
  height: auto;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  width: 30%;
  max-width: 250px;
  font-size: 16px;
  font-family: "nexa-bold";
  transition: width 0.3s;
  border: 2px solid #fff;
  border-radius: 30px;
  background-color: #fff;
  position: relative;
}

.search-bar input[type="text"] {
  flex: 1;
  padding: 5px;
  margin: 0;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: "nexa-light";
  width: calc(100% - 30px);
}

.search-bar input[type="text"]::placeholder {
  color: #e8891d;
  font-size: inherit;
}

.search-bar .search-icon {
  font-size: 25px;
  color: #e8891d;
  margin-left: 5px;
  cursor: pointer;
}

.search-bar .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.search-bar .search-results a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #e8891d;
  border-bottom: 1px solid #ccc;
}

.search-bar .search-results a:last-child {
  border-bottom: none;
}

.search-bar .search-results a:hover {
  background-color: #f2f2f2;
}

.about-section {
  text-align: center;
  padding: 3rem;
  background-color: #fff;
  font-family: "Nexa-Bold";
}

.about-header {
  margin-bottom: 1rem;
  font-size: 2.5em;
  color: #006373;
}

.about-section p {
  margin-bottom: 2rem;
  font-size: 1.3em;
  color: #000;
  line-height: 1.6;
  font-family: "Nexa-Light";
}

.about-heading {
  font-size: 2em;
  color: #006373;
  margin-bottom: 1rem;
}

.about-heading span {
  color: #e67e22;
}

.slider {
  padding-bottom: 15px;
  overflow: hidden;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slide-track {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  width: 300px;
  flex-shrink: 0;
  padding: 10px;
  box-sizing: border-box;
}

.slide img {
  max-width: 100%;
  border-radius: 15px;
}

.slide-title {
  text-align: center;
  font-size: 1.2em;
  color: #e8891d;
  font-family: "Nexa-Bold";
}

.button {
  border-radius: 12px; 
  border: none; 
  padding: 12px 24px; 
  background-color: #e67e22; 
  color: white;
  font-size: 20px; 
  font-weight: bold; 
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-right: 30px;
  text-decoration: none;
}

.button:hover {
  background-color: #006373; 
  transform: scale(1.05); 
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
}

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer p {
  margin: 0;
  font-size: 14px;
  font-family: "Nexa-Light";
  color: #000;
}

.footer a {
  text-decoration: none;
  color: #e8891d;
  transition: color 0.3s;
}

.footer a:hover {
  color: #b35a20;
}
