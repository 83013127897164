html {
  scroll-behavior: smooth;
}
.how-container {
  height: calc(100vh - 80px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px; /* Adjust this padding as needed */
}
.how-heading {
  margin-top: 10px;
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #006373;
  font-family: "Nexa-Bold";
}
.how-content {
  background-color: #006373;
  width: 100%;
  flex-grow: 1; /* This makes the how-content take up the remaining space */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: white; /* Assuming you want the text color to be white */
  padding: 20px; /* Add some padding if needed */
}

@media (max-width: 768px) {
  .how-heading {
    font-size: 2rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .how-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}
